import React from "react";
import { Provider } from "react-redux";
import store from "./src/store";

import "./src/styles/global.css";
import "react-toastify/dist/ReactToastify.css";

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<Provider store={store}>{element}</Provider>
		</>
	);
};

// export const onRouteUpdate = () => {
// 	console.log(window.location.pathname, "<<-- window.location.pathname")
// 	if (
// 		typeof window !== "undefined" &&
// 		window.location.pathname === "/checkout/"
// 	) {
// 		// ClickMagick tracking script
// 		const clickmagick_script = document.getElementById("clickmagick_script");
// 		const clickmagick_lib = document.getElementById("clickmagick_lib");

// 		if (!clickmagick_lib && !clickmagick_script) {
// 			console.log("No clickmagick");
// 		} else {
// 			// Remove clickmagick
// 			console.log("Removing clickmagick...")
// 			document.removeChild(clickmagick_lib);
// 			document.removeChild(clickmagick_script);
// 			console.log("Clickmagick removed")
// 		}
// 	}
// };
