import { CartItem, Coupon, ShippingMethod } from "../types";
import { toast } from "react-toastify";
import { updateUserCart } from "../services/woocommerceServices/Customer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckStock } from "../services/woocommerceServices/Products";
import axios from "axios";
interface InitialState {
	items: CartItem[];
	subTotal: string;
	total: string;
	shippingMethod: Partial<ShippingMethod>;
	shippingSubTotal: string;
	coupon: Coupon[];
	discount: string;
	couponLoading: boolean;
	refreshUI: boolean;
	SubTotalWithCoupon: string;
}

///************************** THUNK *********************************** */

interface AdjustQuantityPayload {
	action_type: "ADD" | "REMOVE" | "REPLACE";
	productId: string;
	quantity: number;
	product: CartItem; // Ensure this type is correct
}

interface CheckAndAddToCartPayload {
	item: any;
	userId: string | null;
	quantity: number;
}

interface AdjustQuantityResponse extends AdjustQuantityPayload {
	apiData: any; // Replace `any` with the actual type if known
}

export const adjustQuantity: any = createAsyncThunk<
	AdjustQuantityResponse,
	AdjustQuantityPayload
>("cart/quantityAdjustment", async (payload, { rejectWithValue }) => {
	try {
		let id;
		let quantity;

		// FIND PRODUCT ID
		if (payload.product.variant) {
			id = payload.product.variant.id;
		} else {
			id = payload.product.productId;
		}
		//
		if (payload.action_type === "ADD") {
			quantity = Number(payload.product.quantity) + 1;
		} else if (payload.action_type === "REMOVE") {
			if (payload.product.quantity > 1) {
				quantity = Number(payload.product.quantity) - 1;
			}
		} else if (payload.action_type === "REPLACE") {
			quantity = Number(payload.quantity);
		}

		// Call the stock check API
		const stockStatus: any = await CheckStock(id, quantity);

		// Check if out of stock based on API response
		if (stockStatus.instock === false) {
			toast.error("This product is out of stock.");
			return rejectWithValue("Out of stock");
		}

		// Return the payload including the API data
		return { ...payload, apiData: stockStatus };
	} catch (error: any) {
		// Handle any errors
		return rejectWithValue(error.message);
	}
});

///

export const checkAndAddToCart = createAsyncThunk<
	{
		userId: any;
		item: CheckAndAddToCartPayload["item"];
		apiData: any;
	},
	CheckAndAddToCartPayload
>("cart/checkAndAddToCart", async (payload, { getState, rejectWithValue }) => {
	try {
		const state: any = getState();
		const existingItems = state.cart.items;

		let id;
		// FIND PRODUCT ID
		if (payload.item.variant) {
			id = payload.item.variant.id;
		} else {
			id = payload.item.productId;
		}

		// PRODUCT ID EXISTS OR NOT
		const index = existingItems.findIndex((i: any) => {
			if (!i?.variant?.id) {
				return i.productId === payload.item.productId;
			} else {
				return i?.variant?.id === payload.item.variant?.id;
			}
		});

		let quantity;
		// IF EXISTS THE ADD THE QUANTITY AND AND THEN CEHCK
		if (index > -1) {
			const item = existingItems?.[index];
			quantity = item.quantity + payload.quantity;
		} else {
			// IF DONT THEN QUANTITY 1
			quantity = payload.quantity;
		}
		// Call the stock check API
		const stockStatus: any = await CheckStock(id, quantity);

		// Check if out of stock based on API response
		if (stockStatus.instock === false) {
			toast.error("This product is out of stock.");
			return rejectWithValue("Out of stock");
		}

		// Return the payload including the API data
		return { ...payload, apiData: stockStatus };
	} catch (error: any) {
		// console.error("Error in checkAndAddToCart:", error.message);
		return rejectWithValue(error.message);
	}
});

///************************************************************* */

const initialState: InitialState = {
	items:
		typeof window !== "undefined"
			? JSON.parse(localStorage.getItem("cartData") as string) || []
			: [],
	subTotal:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",

	total:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",
	shippingMethod: {},
	shippingSubTotal:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",
	coupon: [],
	discount: "0.00",
	couponLoading: false,
	refreshUI: false,
	SubTotalWithCoupon: typeof window !== "undefined"
		? localStorage.getItem("subTotal") ?? "0.00"
		: "0.00",
};

const calculateSubTotal = (items: CartItem[]) => {
	return items
		?.reduce((sum: any, item: CartItem) => {
			let price: any = item.variant?.price ?? item.price;
			return sum + price * item.quantity;
		}, 0)
		.toFixed(2);
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addToCart: (state, action) => {
			if (state.items.length === 0) {
				state.items = [action.payload.item];
			} else {
				const index = state.items.findIndex((i: any) => {
					if (!i?.variant?.id) {
						return i.productId === action.payload.item.productId;
					} else {
						return i?.variant?.id === action.payload.item.variant?.id;
					}
				});

				if (index > -1) {
					const item = state.items?.[index];
					item.quantity = item.quantity + action.payload.item.quantity;
					item.price = Number(item.price);
				} else {
					state.items = [...state.items, action.payload.item];
				}
			}

			const subTotal = state.items
				?.reduce((sum: any, item: CartItem) => {
					let price: any = item?.variant ? item?.variant?.price : item?.price;
					return sum + price * item.quantity;
				}, 0)
				.toFixed(2);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			state.SubTotalWithCoupon = subTotal.toFixed(2);
			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					console.log(amount, "<<== amount")

					state.SubTotalWithCoupon = (Number(state.SubTotalWithCoupon) - Number(amount)).toFixed(2);
					console.log(subTotal - Number(amount), "<<-- SubTotal with coupon")

					state.total = (subTotal - Number(amount)).toFixed(2);
				})
			} else {
				state.SubTotalWithCoupon = subTotal;
				state.total = subTotal;
			}
			localStorage.setItem("subTotal", subTotal);
			localStorage.setItem("cartData", JSON.stringify(state.items));

			if (action.payload.userId !== null) {
				updateUserCart(action.payload.userId, state.items);
			}
		},
		removeFromCart: (state, action) => {
			state.items?.splice(action.payload.productIndex, 1);

			// Related coupon removal
			let couponIndex = state.coupon.findIndex(item => item.product_ids.includes(action.payload.productId));
			state.discount = (Number(state.discount) + Number(state.coupon[couponIndex]?.amount)).toFixed(2);

			const productIndex = state.items.findIndex(item => item.productId === action.payload.productId);
			console.log(couponIndex, productIndex, "<<-- productIndex")
			if (couponIndex > -1 && productIndex < 0) {
				state.coupon.splice(couponIndex, 1);
			}

			const subTotal = state.items
				?.reduce((sum: any, item: CartItem) => {
					let price: any = item.variant?.price ?? item.price;
					return sum + price * item.quantity;
				}, 0)
				.toFixed(2);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.SubTotalWithCoupon = (subTotal - Number(amount)).toFixed(2);

					state.total = (subTotal - Number(amount)).toFixed(2);
				})
			} else {
				state.total = subTotal;
			}
			localStorage.setItem("subTotal", subTotal);
			localStorage.setItem("cartData", JSON.stringify(state.items));

			if (action.payload.userId !== null) {
				updateUserCart(action.payload.userId, state.items);
			}
		},
		updateShippingMethod: (state, action) => {
			state.shippingMethod = action.payload;
			state.total = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);
			state.shippingSubTotal = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);

			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.total = (Number(state.total) - Number(amount)).toFixed(2);
					state.shippingSubTotal = (Number(state.shippingSubTotal) - Number(amount)).toFixed(2);
				})
			}

			localStorage.setItem("shippingMethod", JSON.stringify(action.payload));
		},
		removeShippingMethod: (state, action) => {
			console.log(action.payload.total, "<<-- action.payload")
			state.shippingMethod = {};
			state.total = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);
			state.shippingSubTotal = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);

			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.total = (Number(state.total) - Number(amount)).toFixed(2);
					state.shippingSubTotal = (Number(state.shippingSubTotal) - Number(amount)).toFixed(2);
				})
			}

			localStorage.removeItem("shippingMethod");
		},
		applyCoupon: (state, action) => {
			let discount = 0;
			if (action.payload?.discount_type === "fixed_cart") {
				discount = parseFloat(action.payload.amount);
			} else if (action.payload?.discount_type === "percent") {
				discount =
					parseFloat(state.subTotal) *
					(parseFloat(action.payload.amount) / 100);
			} else if (action.payload?.discount_type === "fixed_product") {
				discount = state.items.reduce(
					(total: any, item: any) =>
						total +
						(action.payload.product_ids.includes(item.productId)
							? parseFloat(action.payload.amount) * item.quantity
							: 0),
					0
				);
			}

			state.discount = discount.toString();
			if (state.coupon.findIndex((i) => i.code === action.payload.code) > -1) {
				toast.error("Coupon is being used.");
			} else {
				state.coupon = [...state.coupon, action.payload];
				state.SubTotalWithCoupon = (Number(state.SubTotalWithCoupon) - discount).toFixed(2);
				state.total = (Number(state.total) - discount).toFixed(2);
				toast.success("Coupon applied successfully.");
			}
		},
		removeCoupon: (state, action) => {
			let index = state.coupon.findIndex((i) => i.id === action.payload.id);
			if (index > -1) {
				state.coupon.splice(index, 1);

				const amount = action.payload.discount_type === "percent" ? (Number(state.subTotal) * (action.payload.amount / 100)).toFixed(2) : action.payload.amount

				state.SubTotalWithCoupon = (Number(state.SubTotalWithCoupon) + Number(amount)).toFixed(2);

				state.total = (
					Number(state.total) + Number(amount)
				).toFixed(2);
				state.discount = (Number(state.discount) - action.payload.amount).toString();
			}
		},
		userCartItems: (state, action) => {
			state.items = action.payload.items

			const subTotal = calculateSubTotal(state.items);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			state.SubTotalWithCoupon = subTotal;
			state.total = subTotal;

			localStorage.setItem("cartData", JSON.stringify(state.items));
			localStorage.setItem("subTotal", subTotal);

			if (action.payload.userId !== null) {
				updateUserCart(action.payload.userId, state.items);
			}
		},
		setCartLoading: (state, action) => {
			state.couponLoading = action.payload;
		},
		setBackToPreviousState: (state, action) => {
			// localStorage.setItem("localTrackingState", JSON.stringify(!action.payload));
			state.refreshUI = !action.payload;
		},
		quantityAdjustment: (state, action) => {
			const { action_type, productId, quantity, product } = action.payload;

			try {
				//FIND ITEM INDEX
				const index = state.items.findIndex((i: any) => {
					if (!i?.variant?.id) {
						return i.productId === productId;
					} else {
						return i?.variant?.id === product.variant?.id;
					}
				});

				//ITEM PRESENT OR NOT
				if (index > -1) {
					const item = state.items[index];
					// TYPE OF ACTIONS
					if (action_type === "ADD") {
						item.quantity += quantity;
					} else if (action_type === "REMOVE") {
						item.quantity = Math.max(1, item.quantity - quantity);
					} else if (action_type === "REPLACE") {
						item.quantity = quantity;
					}
					// SUBTOTAL CALCULATOR
					const subTotal = calculateSubTotal(state.items);

					// SET ITEMS
					state.subTotal = subTotal;
					state.shippingSubTotal = subTotal;
					state.SubTotalWithCoupon = subTotal;
					state.total = subTotal;
					localStorage.setItem("subTotal", subTotal);
					localStorage.setItem("cartData", JSON.stringify(state.items));
				}
			} catch (error) {
				// console.error("Error adjusting quantity:", error);
			}
		},
		clearCart: (state) => {
			state.items = [];
			state.subTotal = "0.00";
			state.total = "0.00";
			state.shippingMethod = {};
			state.shippingSubTotal = "0.00";
			state.SubTotalWithCoupon = "0.00";
			state.coupon = [];

			localStorage.removeItem("cartData")
		}
	},
	extraReducers: (builder) => {
		// ADJUST QUANTITY IN  CART SLIDER START
		builder.addCase(adjustQuantity.fulfilled, (state, action) => {
			const { action_type, productId, quantity, product, apiData } =
				action.payload;
			const index = state.items.findIndex((i: any) => {
				if (!i?.variant?.id) {
					return i.productId === productId;
				} else {
					return i?.variant?.id === product.variant?.id;
				}
			});

			if (index > -1) {
				const item = state.items[index];

				if (action_type === "ADD") {
					item.quantity += quantity;
				} else if (action_type === "REMOVE") {
					item.quantity = Math.max(1, item.quantity - quantity);
				} else if (action_type === "REPLACE") {
					item.quantity = quantity;
				}

				const subTotal = calculateSubTotal(state.items);

				state.subTotal = subTotal;
				state.shippingSubTotal = subTotal;
				state.total = subTotal;

				localStorage.setItem("subTotal", subTotal);
				localStorage.setItem("cartData", JSON.stringify(state.items));

				if (action.payload.userId !== null) {
					updateUserCart(action.payload.userId, state.items);
				}

				// toast.success("Item added to cart");
			}
		});

		builder.addCase(adjustQuantity.rejected, (state, action) => {
			// console.error("Error in quantity adjustment:", action.payload);
		});
		// ADJUST QUANTITY IN  CART SLIDER END

		// ADJUST QUANTITY IN PRODUCT DETAILS START
		builder.addCase(checkAndAddToCart.fulfilled, (state, action: any) => {
			if (state.items.length === 0) {
				state.items = [action.payload.item];
			} else {
				const isGrouped = action.payload?.item?.linkedProducts?.length > 0;
				if (isGrouped) {
					// TODO: Need to refactor this
					action.payload.item.linkedProducts.sort((a: any, b: any) => a.id > b.id);
					const stateItem = state.items.find(item =>
						JSON.stringify(item.linkedProducts) === JSON.stringify(action.payload.item.linkedProducts)
					);
					console.log(stateItem, "<<-- stateItem");
					if (stateItem) {
						stateItem.quantity += action.payload.quantity;
					} else {
						state.items.push(action.payload.item);
					}

				} else {
					const index = state.items.findIndex((i: any) => {
						if (!i?.variant?.id) {
							return i.productId === action.payload.item.productId;
						} else {
							return i?.variant?.id === action.payload.item.variant?.id;
						}
					});

					if (index > -1) {
						const item = state.items?.[index];
						item.quantity = item.quantity + action.payload.quantity;
						item.price = Number(item.price);
					} else {
						state.items = [...state.items, action.payload.item];
					}
				}
			}

			const subTotal = state.items
				?.reduce((sum: any, item: CartItem) => {
					let price: any = item?.variant?.price ? Number(item?.variant?.price) : Number(item?.price);
					return sum + price * item.quantity;
				}, 0)
				.toFixed(2);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			state.total = subTotal;
			console.log("THIS BLOCK CALLED");
			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.SubTotalWithCoupon = (subTotal - Number(amount)).toFixed(2);

					state.total = (subTotal - Number(amount)).toFixed(2);
				})
			} else {
				state.SubTotalWithCoupon = subTotal;
				state.total = subTotal;
			}

			localStorage.setItem("subTotal", subTotal);
			localStorage.setItem("cartData", JSON.stringify(state.items));
			if (action.payload.userId !== null) {
				updateUserCart(action.payload.userId, state.items);
			}
			toast.success("Item added to cart");

		});

		builder.addCase(checkAndAddToCart.rejected, (state, action) => {
			// console.error("Failed to add product to cart:", action.payload);
		});
		// ADJUST QUANTITY IN PRODUCT DETAILS END
	},
});

export const {
	addToCart,
	removeFromCart,
	updateShippingMethod,
	applyCoupon,
	removeCoupon,
	userCartItems,
	setCartLoading,
	quantityAdjustment,
	clearCart,
	setBackToPreviousState,
	removeShippingMethod
} = cartSlice.actions;

export default cartSlice.reducer;
